import React from "react";
import { graphql } from "gatsby"
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import MiniContent from '../../components/mini-content.js'
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

const QuergedachtPage = ({ data: { image: {childImageSharp: { gatsbyImageData: image } }, categories: { edges: angebote } } }) => {

  return (
    <Layout>
      <SEO
        keywords={[`jung`, `multidisziplinär`, `systemisch`]}
        title="quergedacht"
      />
      
      { image ? <div className="p-2"><GatsbyImage image={{ ...image }} className='mb-0 md:mb-16' /></div> : null }

      <div className='flex flex-wrap'>

        <MiniContent
          key="Team"
          itemsPerRow={2}
          title="Team"
          content="Wer wir sind"
          link={{ url: "/quergedacht/team" }}
        />

        { angebote.map(x => (
          <MiniContent
            key={x.node.frontmatter.title}
            itemsPerRow={2}
            title={x.node.frontmatter.title}
            content={x.node.frontmatter.teaser}
            link={{ url: x.node.frontmatter.slug ? x.node.frontmatter.slug : null }}
          />))
        }
      </div>

    </Layout>
  )
}

QuergedachtPage.propTypes = {
  data: PropTypes.object,
  angebote: PropTypes.arrayOf(PropTypes.object)
}

export default QuergedachtPage;
export const pageQuery = graphql`
query imageAndCategories {
  categories: allMdx(
    filter: {fields: {collection: {eq: "quergedacht"}}}
    sort:{fields:frontmatter___order} ) {
    edges {
      node {
        excerpt
        frontmatter {
          title
          slug
          teaser
        }
      }
    }
  }
  image: file(relativePath: {eq: "team.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}`
